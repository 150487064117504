import React from 'react'
import { compose, pure } from 'recompose'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import EconomiquementIntelligent from '../../components/assets/EconomiquementIntelligent'

const CompositionPage = () => <EconomiquementIntelligent />

const echance = compose(
  pure,
  SetLanguageFromPage()
)

export default echance(CompositionPage)
