import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'
import { scroller, Element } from 'react-scroll'
import Helmet from 'react-helmet'
import { Material, Typicons } from 'inno-components/build/Icons'

import {
  Grid,
  Section,
  ImageBasic,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from 'inno-components'

import SecondaryLayout from '../layouts/SecondaryLayout'
import Config from '../../Config'
import Classes from '../../styles/classes'
import IconSection from '../../icons/IconSection'

const styles = StyleSheet.create({
  sectionTop: {
    backgroundColor: Config.colors.quaternary
  },
  sectionWhite: {
    backgroundColor: '#FFFFFF'
  },
  grid: {
    margin: 0
  },
  container: {
    minHeight: '300px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    overflowY: 'auto'
  },
  icon: {
    fontSize: '1.3em',
    color: '#FFFFFF'
  }
})

class EconomiquementIntelligent extends Component {
  onSectionClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true
    })
  }

  render() {
    const { t } = this.props
    return (
      <SecondaryLayout subtitle={t('titre')} page="ComponentAssetsEconomiquementIntelligent">
        <Helmet
          title={t('head.title')}
          meta={[
            {
              name: 'description',
              content: t('head.meta-description')
            }
          ]}
        />
        <Section className={css(styles.sectionTop)}>
          <div className={css(styles.container)}>
            <IconSection
              title={t('menu.productivite')}
              height={170}
              fill={Config.colors.primary}
              onClick={() => this.onSectionClick('productivite')}
            >
              <Typicons.TiChartLine className={css(styles.icon)} />
            </IconSection>
            <IconSection
              title={t('menu.couts')}
              height={200}
              fill={Config.colors.secondary}
              onClick={() => this.onSectionClick('couts')}
              direction="down"
            >
              <Material.MdEuroSymbol className={css(styles.icon)} />
            </IconSection>
            <IconSection
              title={t('menu.nocivite')}
              height={180}
              fill={Config.colors.thirdary}
              direction="down"
              onClick={() => this.onSectionClick('logistique')}
            >
              <Typicons.TiSpanner className={css(styles.icon)} />
            </IconSection>
            <IconSection
              title={t('menu.degats')}
              height={160}
              fill={Config.colors.primary}
              direction="down"
              onClick={() => this.onSectionClick('degats')}
            >
              <Typicons.TiFlashOutline className={css(styles.icon)} />
            </IconSection>
            <IconSection
              title={t('menu.efficacite')}
              height={170}
              fill={Config.colors.secondary}
              onClick={() => this.onSectionClick('efficacite')}
            >
              <Typicons.TiInputChecked className={css(styles.icon)} />
            </IconSection>
          </div>
        </Section>
        <Element name="productivite" id="productivite">
          <Section className={css(Classes.section)}>
            <h2 className={css(Classes.h2)}>{t('sections.0.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.0')
              }}
            />
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                className={css(Classes.image)}
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image030.png"
              />
            </div>
            <p
              className={css(Classes.headline, Classes.bold)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.accroches.0')
              }}
            />

            <Grid className={css(styles.grid)} stackable>
              <Grid.Row>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.0.sous-titres.0')}</h3>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.0.paragraphes.1')
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.0.sous-titres.1')}</h3>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.0.paragraphes.2')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <h3 className={css(Classes.h3)}>{t('sections.0.sous-titres.2')}</h3>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.0.paragraphes.3')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.0.sous-titres.3')}</h3>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.0.paragraphes.4')
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.0.sous-titres.4')}</h3>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.0.paragraphes.5')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.0.paragraphes.6')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <p
              className={css(Classes.headline, Classes.bold)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.accroches.1')
              }}
            />
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={css(Classes.tableCellHead)}>
                      {t('sections.0.tableau.titres.0')}
                    </TableCell>
                    <TableCell className={css(Classes.tableCellHead)}>
                      {t('sections.0.tableau.titres.1')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('sections.0.tableau.lignes.0')}</TableCell>
                    <TableCell>{t('sections.0.tableau.lignes.1')}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <p className={css(Classes.tableCaption)}>{t('sections.0.tableau.description')}</p>
          </Section>
        </Element>
        <Element name="couts" id="couts">
          <Section className={css(Classes.section, styles.sectionWhite)}>
            <h2 className={css(Classes.h2)}>{t('sections.1.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.0')
              }}
            />
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                className={css(Classes.image)}
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image029.png"
              />
            </div>
            <p
              className={css(Classes.headline, Classes.bold)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.accroches.0')
              }}
            />
            <Grid className={css(styles.grid)} stackable padded>
              <Grid.Row>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.1.sous-titres.0')}</h3>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.1.paragraphes.1')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.1.paragraphes.2')
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.1.sous-titres.1')}</h3>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.1.paragraphes.3')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.1.paragraphes.4')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.1.sous-titres.2')}</h3>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.1.paragraphes.5')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <p
              className={css(Classes.headline, Classes.bold)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.accroches.1')
              }}
            />
          </Section>
        </Element>
        <Element name="logistique" id="logistique">
          <Section className={css(Classes.section)}>
            <h2 className={css(Classes.h2)}>{t('sections.2.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.0')
              }}
            />
            <p className={css(Classes.headline, Classes.bold)}>{t('sections.2.accroche.0')}</p>
            <Grid className={css(styles.grid)} stackable>
              <Grid.Row>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.2.sous-titres.0')}</h3>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.2.paragraphes.1')
                    }}
                  />
                  <ul className={css(Classes.ul)}>
                    <li
                      className={css(Classes.li)}
                      dangerouslySetInnerHTML={{
                        __html: t('sections.2.paragraphes.2')
                      }}
                    />
                    <li
                      className={css(Classes.li)}
                      dangerouslySetInnerHTML={{
                        __html: t('sections.2.paragraphes.3')
                      }}
                    />
                  </ul>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.2.paragraphes.4')
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.2.sous-titres.1')}</h3>
                  <p
                    className={css(Classes.paragraph, Classes.bold)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.2.paragraphes.5')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph, Classes.bold)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.2.paragraphes.6')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.2.paragraphes.7')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.2.paragraphes.8')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Section>
        </Element>
        <Element name="degats" id="degats">
          <Section className={css(Classes.section, styles.sectionWhite)}>
            <h2 className={css(Classes.h2)}>{t('sections.3.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.3.paragraphes.0')
              }}
            />
            <p className={css(Classes.headline, Classes.bold)}>{t('sections.3.accroches.0')}</p>
            <Grid className={css(styles.grid)} stackable>
              <Grid.Row>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.3.sous-titres.0')}</h3>
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.3.paragraphes.1')
                    }}
                  />

                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.3.paragraphes.2')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.3.paragraphes.3')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph, Classes.bold)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.3.paragraphes.4')
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <h3 className={css(Classes.h3)}>{t('sections.3.sous-titres.1')}</h3>
                  <p
                    className={css(Classes.paragraph, Classes.bold)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.3.paragraphes.5')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph, Classes.bold)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.3.paragraphes.6')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph, Classes.bold)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.3.paragraphes.7')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph, Classes.bold)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.3.paragraphes.8')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph, Classes.bold)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.3.paragraphes.9')
                    }}
                  />
                  <p
                    className={css(Classes.paragraph, Classes.bold)}
                    dangerouslySetInnerHTML={{
                      __html: t('sections.3.paragraphes.10')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Section>
        </Element>
        <Element name="efficacite" id="efficacite">
          <Section className={css(Classes.section)}>
            <h2 className={css(Classes.h2)}>{t('sections.4.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.4.paragraphes.0')
              }}
            />
            <h3 className={css(Classes.h3)}>{t('sections.4.paragraphes.1')}</h3>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.4.paragraphes.2')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.4.paragraphes.3')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.4.paragraphes.4')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.4.paragraphes.5')
              }}
            />
            <Grid>
              <Grid.Row centered>
                <Grid.Column width={5}>
                  <ImageBasic
                    className={css(Classes.image)}
                    src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image031.png"
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <ImageBasic
                    className={css(Classes.image)}
                    src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image032.png"
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <ImageBasic
                    className={css(Classes.image)}
                    src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image033.png"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Section>
        </Element>
      </SecondaryLayout>
    )
  }
}

export default withNamespaces('economiquement-intelligent')(EconomiquementIntelligent)
